@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.sideBar {
  position: fixed;
  z-index: 1100;
  background-color: white;
  width: 25rem;
  
  height: 100vh;
  transition: 0.3s;
  box-shadow: $globalShadow;
  color: $primaryGray;
  padding: 2rem 1rem;

  .fa-xmark {
    display: none;
    @include mQ(768px) {
      display: block;
      position: absolute;
      right: 1rem;
      top: 1rem;
      font-size: $HeadFont;
      cursor: pointer;
    }
  }

  .sideBar-title {
    display: flex;
    h1 {
      font-size: $HeadFont;
      line-height: $HeadLineSpacing;
      color: $primaryBlack;
      font-weight: bold;
      margin-left: 1rem;
    }
  }
  .sideBar-menu {
    font-size: $MediumFont;
    line-height: $MediumLineSpacing;
    margin-top: 2rem;
    .sideItem {
      cursor: pointer;
      text-transform: capitalize;
      padding: 1rem 0px;
      i {
        margin-right: 1rem;
      }
    }
    .sideItem:hover,
    .selected {
      color: $primary;
      font-weight: bold;
    }
    padding: 0.5rem 0;
  }
}
