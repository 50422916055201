@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";
.home-top-shape {
  position: absolute;
  left: 0;
  top: 0;
  transform: scaleX(-1);
  height: 100vh;
}
.home-top-shape img {
  height: 100%;
  filter: invert(2%) sepia(93%) saturate(433%) hue-rotate(181deg)
    brightness(97%) contrast(96%);
}
.about-section {
  .btn {
    border-radius: 2.5rem;
  }
  .start-home-content {
    padding-right: 4rem;
  }
  .start_home.demo3 .start-home-content span {
    color: #00c9a7;
    font-weight: 500;
    font-size: $MediumFont;
    margin-bottom: 1rem;
  }
  .start_home.demo3 .start-home-content p {
    color: $primaryGray;
    line-height: $MediumLineSpacing;
  }
  .start_home.demo3 .start-home-content h1 {
    background: $primarybackground;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7450fe', endColorstr='#901ec1',GradientType=1 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  .start_home.demo3 .theme-btn {
    padding: 20px 40px;
  }
  .banner-btns {
    position: relative;
    z-index: 1; 
  }
  .start_home.demo3 .banner-btns .btn {
    padding: 12px 25px;
    min-width: 200px;
    font-size: 16px;
    margin-top: 30px;
  }

  .start_home {
    position: relative;
    background-size: cover;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffffff;
  }
  .start_home.demo1 {
    //   background: url(../images/start-home-bg.jpg) no-repeat fixed;
    /*margin-bottom: 80px;*/
    height: 700px;
  }
  .start-home-img {
    height: 600px;
    @include flexCenter;
    flex-flow: column wrap;
  }
  .start_home.demo1:before,
  .start_home.demo2:before {
    position: absolute;
    content: "";
    background: linear-gradient(
      to right,
      rgba(144, 30, 193, 0.9) 0%,
      rgb(107, 73, 238, 0.9) 51%,
      rgba(144, 30, 193, 0.9) 100%
    );
    background-size: 200% auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .shape-bottom img.bottom-shape {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    z-index: 1;
    width: 100%;
    margin: 0 auto;
    /*transform: rotateX(-1);*/
  }
  .start-home-content {
    height: 700px;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .start-home-content h1 {
    text-transform: capitalize;
    font-size: 40px;
    color: #fff;
  }
  .start-home-content p {
    color: #fff;
    font-size: 16px;
  }
  .apple-button {
    width: 180px;
    display: inline-block;
    margin-right: 15px;
    margin-top: 30px;
  }
  .app-button a {
    border: 2px solid #ffffff;
    border-radius: 5px;
    padding: 8px 15px;
    text-decoration: none;
    color: #ffffff;
    display: block;
  }
  .apple-button .slider-button-icon {
    padding: 0px 20px 6px 3px;
    float: left;
  }
  .apple-button .slider-button-icon i {
    font-size: 32px;
    line-height: 36px;
  }
  .slider-button-title {
    text-transform: uppercase;
  }
  .left-side-content p {
    max-width: 575px;
  }
  .slider-button-title p {
    margin: 0px;
    padding: 0;
    font-size: 11px;
    line-height: 18px;
  }
  .slider-button-title h3 {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #fff;
  }
  .google-button {
    width: 180px;
    display: inline-block;
    margin-right: 15px;
    margin-top: 30px;
  }
  .google-button .slider-button-icon {
    padding: 0px 15px 6px 3px;
    float: left;
  }
  .google-button .slider-button-icon i {
    font-size: 26px;
    line-height: 38px;
  }
  .app-button a:hover {
    background: #fff;
    border: 2px solid transparent;
    color: #911ec2;
  }
  .app-button a:hover p,
  .app-button a:hover h3 {
    color: #911ec2;
  }
  .start-home-img img {
    animation: floating2 7s infinite;
    -webkit-animation: floating2 7s infinite;
  }
  .start_home.demo1 .start-home-img img {
    position: absolute;
    right: 0;
    z-index: 99;
  }
}
