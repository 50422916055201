@import "./abstracts/variables.scss";
@import "./abstracts/mixins.scss";
.arabicContainer {
  direction: rtl;

  .navbar {
    .dropdown-menu {
      ul {
        text-align: right;
      }
    }
    .site-logo img {
      margin-right: 0;
      margin-left: 1rem;
    }
    .navbar-toggler-rtl {
      position: absolute;
      left: 1px;
      top: 0.6rem;
      &:focus {
        box-shadow: none;
      }
    }
  
  }
  #why-appscoper ul.list-checked li i {
    right: 0;
  }
  #why-appscoper ul.list-checked li {
    padding-right: 40px;
  }
  #pricing .card-body ul li span {
    right: 0;
  }
  #pricing .card-body ul li {
    padding-right: 35px;
  }
  .footer-title:before {
    right: 0;
  }
  .footer-title {
    padding-right: 25px;
  }
  #faqs button {
    text-align: right;
  }
  #faqs .card-body ul li {
    padding-right: 35px;
  }

  #faqs .card-body ul li span {
    right: 0;
  }

  #contact .contact-block-side i {
    right: 0;
  }
  #contact .contact-block-side {
    padding-right: 70px;
  }

  .newsletter-form input[type="email"] {
    width: 70%; /* Adjust input width */
    height: 45px;
    background: #fff;
    box-shadow: none;
    border: none;
    padding-left: 20px;
    border-radius: 30px 0 0 30px;
  }

  .newsletter-form button {
    height: 100%;
    border-radius: 30px 0 0 30px;
    width: 30%; /* Adjust button width */
    margin-right: 5px; /* Add some margin between input and button */
  }

  .contact-form-block {
    margin: 0 60px 0 0px;
  }
  .footer-links li a {
    padding-right: 5px;
  }

  .privacy-container {
    .features-style {
      .feature-block {
        text-align: right;
        .feature-icon {
          i:before {
            margin-right: 0;
          }
        }
      }

      ul.list-checked {
        li {
          padding-right: 40px;
          padding-left: 0;
          i {
            right: 0;
          }
        }
      }
    }
  }
}
