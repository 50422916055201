@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

body {
  .theme-btn{
    background-image:  $primarybackground;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    background-size: 200% auto;
    color: #fff !important;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
    box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
}
.theme-btn:hover {
    background-position: right center !important;
}
.theme-btn-sec{
    color: $primary;
    background: rgba(144, 30, 193, 0.07);
    transition: all 500ms;
}
.theme-btn-sec:hover {
    background: $primarybackground;
    background-size: 200% auto;
    color: #fff;
}
}
