// colors
$primary: #1b78b2;
$secondary: #77d0e8;
$primaryGray: #77838f;
$primaryBlack: #333;
//background
$primarybackground: linear-gradient(
  119.34deg,
  #1b78b2,
  rgba(41, 148, 214, 0.79) 23.55%,
  rgba(18, 135, 208, 0.62) 80.37%
);
// Typography Start
// Font sizes
$GlobalFont: 62.5%;
$HeadFont: 1.8rem;
$MediumFont: 1.4rem;
$smallFont: 1.2rem;

// Line Heights
$HeadLineSpacing: 4rem;
$MediumLineSpacing: 3rem;
$smallLineSpacing: 1.5rem;

//Typography End

//shadow
$globalShadow: 0px 0px 1.5px $primaryGray;

$globalRadius: 22.5px;
