@import "./abstracts/variables.scss";
@import "./abstracts/mixins.scss";

body {
  .page-header {
    padding: 170px 0;
    background: $primarybackground;
  }

  .title-box h1 {
    color: #fff;
    font-size: 42px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  .breadcrumb {
    color: #fff;
    padding: 0;
    font-size: $HeadFont;
  }
  .privacy-container {
    .features-style {
      h5 {
        font-size: $HeadFont;
        font-weight: 600;
        line-height: 1.4;
        color: $primaryBlack;
      }
      ul.list-checked {
        margin-top: 30px;
        li {
          position: relative;
          padding-left: 40px;
          margin-bottom: 25px;
          line-height: 30px;
          i {
            color: $primary;
            background: rgba(144, 30, 193, 0.07);
            border-color: transparent;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            font-size: 12px;
            line-height: 30px;
            text-align: center;
            position: absolute;
            top: -2px;
            left: 0;
          }
        }
      }
      .feature-block {
        padding: 30px;
        margin-bottom: 30px;
        text-align: left;
        background: #fff;
        box-shadow: 0px 30px 25px 0px rgb(64 1 4 / 10%),
          0px -6px 0px 0px rgb(248 99 107 / 0%);
        border-radius: 25px;
        .feature-icon {
          width: 100%;
          text-align: left;
          height: auto;
          border-radius: 0;
          font-size: 30px;
          i:before {
            font-size: 60px;
            background: $primarybackground;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-left: 0;
          }
        }
      }
    }
  }
}
