@import "./abstracts/variables.scss";
@import "./abstracts/mixins.scss";

.navbar {
  background-color: transparent;
  color: $primaryBlack;
  font-size: 13px;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1111;
    background: #e7f3fd;
  }
  &.scrolled {
    color: white;

    &::before {
      background: $primarybackground;
    }
    .site-logo img {
      filter: brightness(100);
    }
  }
  backdrop-filter: blur(2px);
  background-size: cover;
  .mainContainer {
    position: relative;
  }
  .navbar-toggler-ltr{
    position: absolute;
    right: 1px;
    top: 0.6rem;

  }
  .navbar-toggler {
   
    &:focus {
      box-shadow: none;
    }
  }
  // .navbar-toggler-icon {
  //   color: white;
  // }
  .word{
    font-size: 12px;
    padding-left: 5px;
  }
  .dropLang {
    padding: 0.5rem;
    // min-width: 9.9rem;
  }
  .dropdown-menu,
  .dropLang {
    cursor: pointer;
  }
  .dropdown {
    .dropdown-menu{
      border: 1px solid #bcd3e2;
      border-radius: 6px;
      h6 {
        font-size: 13px;
      }
      ul {
        color: black;
      }
    }
  }
  .dropdown-menu {
    --bs-dropdown-min-width: 9.9rem;
    padding: 0.5rem;
    border: none;
    border-radius: 0;

    li {
      font-size: $MediumFont;
    }
  }

  .dropdown-menu.show {
    top: 3rem;
  }

  .navbar-expand-md .navbar-nav {
    margin-left: auto;
  }
  .site-logo{
    font-weight: 600;
    width: 150px;
    cursor: pointer;
  }

  .site-logo img {
    cursor: pointer;
    height: 28px;
  }
  .navbar-nav li {
    padding: 15px;
    &:last-child {
      padding-right: 0;
    }
  }
  .navbar-nav .dropdown-menu  li{
    padding:5px !important;
    border-radius: 5px;

  } 
  .word-selected{
    background-color: #2690d1;
    color: white;
  }
  .word:hover{
    background-color: #2690d1;
    color: white;

  }
  .navbar-nav li a {
    text-transform:capitalize;
    font-size: 14px;
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    -ms-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    padding: 0;
    display: inline-block;
  }
}
