.accordion .card{
    border: none;
    margin-bottom: 15px;
}
#faqs .card-header{
    background-image: $primarybackground;
    text-align: left;
    padding: 13px 25px;
    border-radius: 30px !important;
    margin-bottom: 15px;
    transition: all 500ms;
    background-size: 200% auto;
}
#faqs .card-header:hover{
    background-position: right center !important;
}
.accordion .card button {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
}
.accordion .card button:hover, .accordion .card button:focus{
    color: #fff;
    text-decoration: none;
}

.img-box img {
    max-height: auto;
    margin-bottom: 25px;
    height: auto;
    animation: floating2 7s infinite;
    -webkit-animation: floating2 7s infinite;
    max-height: 500px;
}

@keyframes floating2 {
    0% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
    50% {
        -webkit-transform: rotateX(0deg) translateY(15px);
        -moz-transform: rotateX(0deg) translateY(15px);
        -ms-transform: rotateX(0deg) translateY(15px);
        -o-transform: rotateX(0deg) translateY(15px);
        transform: rotateX(0deg) translateY(15px);
    }
    100% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
}

@-webkit-keyframes floating2 {
    0% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
    50% {
        -webkit-transform: rotateX(0deg) translateY(15px);
        -moz-transform: rotateX(0deg) translateY(15px);
        -ms-transform: rotateX(0deg) translateY(15px);
        -o-transform: rotateX(0deg) translateY(15px);
        transform: rotateX(0deg) translateY(15px);
    }
    100% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
}

