@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.text--global,
.css-13cymwt-control,
.css-qbdosj-Input {
  width: 35vw !important;
  font-size: $MediumFont;

  @include placeholder-color(#bdc1caff);
  color: $primaryGray;
  border-radius: 6px;

  &:focus {
    border: 1px solid $primaryGray;
  }
  @include mQ(562px) {
    width: 80vw !important;
  }
  @include mQ(500px) {
    width: 90vw !important;
  }
}

.text--global {
  height: 3.5rem;
  background-color: #f3f4f6ff !important;
  outline: none;
  border: none !important;
  padding: 0rem 1.2rem;
}
