@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import "./abstracts/variables.scss";
@import "./abstracts/mixins.scss";
@import "./style.scss";

@import "./Layout/Navbar.scss";
@import "./Layout/Footer.scss";
@import "./Layout/SideBar.scss";

@import "./components/button.scss";
@import "./components/label.scss";
@import "./components/textInput.scss";
@import "./components/radio.scss";
@import "./components/forms.scss";
@import "./components/contact.scss";
@import "./components/features.scss";
@import "./components/how-it-works.scss";
@import "./components/about.scss";

@import "./pages/index.scss";
@import "./pages/notfound.scss";
@import "./pages/privacy.scss";
@import "./pages/glossary.scss";

@import "./flaticon.scss";
@import "./components/faqs.scss";
@import "./components/pricing.scss";
@import "./components/Why_Appscoper.scss";


* {
  box-sizing: border-box;
  text-decoration: none;
  outline: transparent;
  padding: 0;
  margin: 0;
}

html {
  //typography
  font-size: $GlobalFont;
  hr{
    background-color: #aeaeaeab !important;
    opacity: 100% !important;
  }
  body {
    font-family: "Mulish", sans-serif;

    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0.6rem;
    }

    &::-webkit-scrollbar-thumb {
      background: $primaryGray;
      border-radius: 6px;
    }

    pre {
      font-family: "Inter", sans-serif;
      white-space: pre-wrap; /* Since CSS 2.1 */
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word; /* Internet Explorer 5.5+ */
    }
    .section-header h2 {
      font-size: 34px;
      font-weight: 500;
      line-height: 48px;
      font-weight: 600;
      color: #263b5e;
      margin-bottom: 15px;
      text-transform: capitalize;
    }
    a {
      cursor: pointer;
      text-decoration: none;
      color: inherit;
      &:hover {
        color: inherit;
      }
    }

    .invisible {
      display: none;
    }
    .invalid {
      color: red;
    }
    .mainContainer {
      width: 82.39%;

      @include mQ(1300px) {
        width: 88.39%;
      }
      @include mQ(992px) {
        width: 90%;
      }
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;
    }

    input,
    select,
    textarea,
    button,
    datalist {
      border: none;
      background: none;
    }

    input::-webkit-calendar-picker-indicator {
      display: none !important;
    }

    button {
      cursor: pointer;
    }
  }
  .card-header {
    color: #fff;
    text-align: center;
    padding: 50px 10px 103px 10px;
    border-radius: 15px 15px 0 0 !important;
  }

  .btn {
    border-radius: 25px;
    color: #fff;
    font-size: 14px;
  }
  .btn-link {
    text-decoration: none;
  }
  .btn.focus,
  .btn:focus {
    outline: none !important;
    box-shadow: none;
  }
}
