/*================================================
 [  Table of contents  ]
 ================================================

#1.  Fonts
#2.  Global css
#3.  Common Style (Pagination, Main Titles, Preloader, Back to top)
#4.  Header
     4.1 Header style 1
     4.2 Header style 2
#5.  Home Start Page
     5.1 Start Home Demo1
#6. Responsive

 ======================================
 [ End table content ]
 ======================================*/

/* =============================================================================
#1. Fonts
============================================================================= */

/******  Google fonts ******/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700);

/* =============================================================================
#2. Global css
============================================================================= */

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4;
    font-weight: 600;
    // color: #333;
  }
  a {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    outline: none;
    color: $primary;
  }
  a:hover {
    text-decoration: none;
  }
  li {
    list-style: none;
  }

  p {
    line-height: 1.9;
    color: #77838f;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
  }
  .btn {
    border-radius: 25px;
    color: #fff;
    font-size: 14px;
  }
  .btn.focus,
  .btn:focus {
    outline: none !important;
    box-shadow: none;
  }

  /* =============================================================================
#3. Common Style (Pagination, Main Titles)
============================================================================= */
  .btn-block {
    display: block;
    width: 100%;
  }
  .section-block {
    padding: 80px 0;
  }
  .section-header {
    margin-bottom: 80px;
    text-align: center;
  }
  .section-header h2 {
    font-size: 34px;
    font-weight: 500;
    line-height: 48px;
    color: #263b5e;
    margin-bottom: 15px;
    text-transform: capitalize;
  }
  .section-header p {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0;
  }
  .section-header-style2 h2 {
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .section-header-style2 p {
    font-size: 16px;
  }
  .page-header {
    position: relative;
    background-size: 200% auto;
    // background: url(../images/start-home-bg2.jpg);
    position: relative;
    background-size: cover;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    padding: 170px 0;
  }
  .page-header:before {
    position: absolute;
    content: "";
    background: $primarybackground;
    background-size: 200% auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .page-header .shape-bottom img.bottom-shape {
    transform: scaleX(-1);
  }
  .title-box {
    position: relative;
  }
  .breadcrumb {
    background: transparent;
    color: #fff;
    padding: 0;
  }
  .title-box h1 {
    color: #fff;
    font-size: 42px;
    margin-bottom: 15px;
  }
  .breadcrumb a {
    text-transform: capitalize;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  }
  .breadcrumb span:last-child {
    color: rgba(255, 255, 255, 0.6);
  }
  .owl-dots {
    text-align: center;
    padding: 30px 0;
  }
  .owl-dot.active,
  .owl-dot:focus {
    border: none;
    outline: none;
  }
  .owl-dot span {
    width: 12px;
    height: 7px;
    border-radius: 15px;
    margin-left: 2px;
    margin-right: 2px;
    background: rgba(144, 30, 193, 0.07);
    border: none;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .owl-dot.active span,
  .start-home-slider .owl-dot.active span {
    background: $primary;
    width: 25px;
  }
  .owl-nav .owl-prev,
  .owl-nav .owl-next {
    background: rgba(30, 103, 193, 0.08) !important;
    border: none;
    border-radius: 100%;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0);
    color: $primary !important;
    height: 40px;
    line-height: 3;
    margin: 0 5px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 40px;
    z-index: 9;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    background-size: 200% auto;
  }
  .blog-slider .owl-nav .owl-next {
    left: auto;
    right: -50px;
  }
  .blog-slider .owl-nav .owl-prev {
    left: -50px;
    right: auto;
  }
  .owl-nav .owl-prev:hover,
  .owl-nav .owl-next:hover,
  .owl-nav .owl-prev:focus,
  .owl-nav .owl-next:focus,
  .review_nav span:hover,
  .review_nav span:focus {
    outline: none;
    color: #fff !important;
    background: $primarybackground !important;
  }
  .owl-nav .owl-prev span,
  .owl-nav .owl-next span {
    font-size: 12px;
  }
  .theme-btn {
    background-image: $primarybackground;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    background-size: 200% auto;
    color: #fff !important;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
    box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  }
  .theme-btn:hover {
    background-position: right center !important;
  }
  .theme-btn-sec {
    color: $primary;
    background: rgba(144, 30, 193, 0.07);
    transition: all 500ms;
  }
  .theme-btn-sec:hover {
    background: $primarybackground;
    background-size: 200% auto;
    color: #fff;
  }
  .form-control {
    border: 1px solid #ddd;
    height: 45px;
    padding: 0;
    color: #222;
    text-indent: 15px;
    background-image: none;
    border-radius: 30px;
    box-shadow: none;
    font-weight: 300;
    line-height: 45px;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
    font-size: 13px;
  }
  .form-control:focus {
    outline: 0;
    outline: none;
    box-shadow: none;
    border-color: $primary;
  }
  textarea {
    resize: none;
  }
  .pagination-section {
    margin-bottom: 50px;
  }
  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    background: transparent;
    border: none;
  }
  .page-item:first-child .page-link:focus,
  .page-item:last-child .page-link:focus {
    color: $primary;
    background: rgba(30, 103, 193, 0.08);
  }
  .page-item .page-link {
    -webkit-transition: all 500ms;
    transition: all 500ms;
    border: none;
    cursor: pointer;
    text-align: center;
    min-width: 2.25rem;
    padding: 10px;
    border-radius: 5px;
    color: #77838f;
    font-size: 15px;
  }
  .page-item {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .page-item .page-link:hover {
    color: $primary;
    background: rgba(30, 103, 193, 0.08);
  }
  .page-item.active .page-link {
    background-image: $primarybackground;
    color: #fff;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(0, 20, 46, 0.1);
    box-shadow: 0px 10px 10px 0px rgba(0, 25, 46, 0.1);
    background-size: 200% auto;
  }
  .page-link:focus {
    outline: none;
    box-shadow: none;
  }
  .text-muted {
    color: #8c98a4 !important;
  }
  ul.list-checked {
    margin-top: 30px;
  }
  ul.list-checked li {
    position: relative;
    padding-left: 40px;
    margin-bottom: 25px;
    line-height: 30px;
  }
  ul.list-checked li i {
    color: $primary;
    background: rgba(30, 103, 193, 0.08);
    border-color: transparent;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    position: absolute;
    top: -2px;
    left: 0;
  }
  /*-------------Back to Top--------------*/
  #back-to-top .top {
    position: fixed;
    color: #ffffff;
    background-image: $primarybackground;
    background-size: 200% auto;
    bottom: 45px;
    right: 45px;
    z-index: 999;
    font-size: 16px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  }
  #back-to-top .top:hover {
    background-position: right center !important;
  }
  /*------------- Page Preloader --------------*/
  #preloader {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    z-index: 9999;
    top: 0;
  }
  #preloader-circle {
    position: relative;
    width: 80px;
    height: 80px;
    top: 43%;
    margin: 0 auto;
  }
  #preloader-circle span {
    position: absolute;
    border: 8px solid rgba(30, 92, 193, 0.8);
    border-top: 8px solid transparent;
    border-radius: 999px;
  }

  #preloader-circle span:nth-child(1) {
    width: 80px;
    height: 80px;
    animation: spin-1 2s infinite linear;
  }
  #preloader-circle span:nth-child(2) {
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    animation: spin-2 1s infinite linear;
  }
  @keyframes spin-1 {
    0% {
      transform: rotate(360deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.5;
    }
    100% {
      transform: rotate(0deg);
      opacity: 1;
    }
  }
  @keyframes spin-2 {
    0% {
      transform: rotate(0deg);
      opacity: 0.5;
    }
    50% {
      transform: rotate(180deg);
      opacity: 1;
    }
    100% {
      transform: rotate(360deg);
      opacity: 0.5;
    }
  }

  /* =============================================================================
#5. Home Start Page
============================================================================= */
  /**************  5.1 Start Home Demo1 ***********/
  .start_home {
    position: relative;
    background-size: cover;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffffff;
  }
  .start_home.demo1 {
    // background: url(../images/start-home-bg.jpg) no-repeat fixed;
    /*margin-bottom: 80px;*/
    height: 700px;
  }
  .start_home.demo1:before,
  .start_home.demo2:before {
    position: absolute;
    content: "";
    background: $primarybackground;
    background-size: 200% auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .shape-bottom img.bottom-shape {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    z-index: 1;
    width: 100%;
    margin: 0 auto;
    /*transform: rotateX(-1);*/
  }
  .start-home-content {
    height: 700px;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .start-home-content h1 {
    text-transform: capitalize;
    font-size: 40px;
    color: #fff;
  }
  .start-home-content p {
    color: #fff;
    font-size: 16px;
  }
  .apple-button {
    width: 180px;
    display: inline-block;
    margin-right: 15px;
    margin-top: 30px;
  }
  .app-button a {
    border: 2px solid #ffffff;
    border-radius: 5px;
    padding: 8px 15px;
    text-decoration: none;
    color: #ffffff;
    display: block;
  }
  .apple-button .slider-button-icon {
    padding: 0px 20px 6px 3px;
    float: left;
  }
  .apple-button .slider-button-icon i {
    font-size: 32px;
    line-height: 36px;
  }
  .slider-button-title {
    text-transform: uppercase;
  }
  .left-side-content p {
    max-width: 575px;
  }
  .slider-button-title p {
    margin: 0px;
    padding: 0;
    font-size: 11px;
    line-height: 18px;
  }
  .slider-button-title h3 {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #fff;
  }
  .google-button {
    width: 180px;
    display: inline-block;
    margin-right: 15px;
    margin-top: 30px;
  }
  .google-button .slider-button-icon {
    padding: 0px 15px 6px 3px;
    float: left;
  }
  .google-button .slider-button-icon i {
    font-size: 26px;
    line-height: 38px;
  }
  .app-button a:hover {
    background: #fff;
    border: 2px solid transparent;
    color: $primary;
  }
  .app-button a:hover p,
  .app-button a:hover h3 {
    color: $primary;
  }
  .start-home-img img {
    animation: floating2 7s infinite;
    -webkit-animation: floating2 7s infinite;
  }
  .start_home.demo1 .start-home-img img {
    position: absolute;
    right: 0;
    z-index: 99;
  }

  /**************  5.4 Start Home Demo4 ***********/
  .home-top-shape {
    position: absolute;
    left: 0;
    transform: scaleX(-1);
    height: 750px;
  }
  .home-top-shape img {
    height: 100%;
  }
  .start_home.demo3 .theme-btn {
    padding: 20px 40px;
  }
  .start_home.demo3 .banner-btns .btn {
    padding: 12px 25px;
    min-width: 200px;
    font-size: 16px;
    margin-top: 30px;
  }
  .btn-video {
    color: #00c9a7 !important;
  }
  .btn-video i {
    color: #00c9a7 !important;
    background-color: rgba(0, 201, 167, 0.1) !important;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 55px;
    height: 55px;
    font-size: 18px;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    line-height: 55px;
    margin-right: 5px;
  }
  .btn-video:hover i {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  /* =============================================================================
#6. Responsive
============================================================================= */
  @media (max-width: 768px) {
    .navbar-toggler-icon {
      color: rgba(255, 255, 255, 1);
      border: 2px solid rgba(255, 255, 255, 1);
      width: 40px;
      height: 35px;
      line-height: 33px;
      border-radius: 5px;
    }
    .home-demo3 .navbar-toggler-icon {
      background: #901ec1;
      background: -moz-linear-gradient(to right, #901ec1 2%, #7450fe 82%);
      background: -webkit-linear-gradient(to right, #901ec1 2%, #7450fe 82%);
      background: linear-gradient(to right, #901ec1 2%, #7450fe 82%);
      background: linear-gradient(to right, #901ec1 2%, #7450fe 82%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7450fe', endColorstr='#901ec1',GradientType=1 );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border: 1px solid #901ec1;
    }
    .navbar-toggler:focus,
    .navbar-toggler:hover {
      outline: none;
    }
    .start_home.demo2 {
      height: 100vh;
    }
    .start-home-content {
      height: 100vh;
    }
    .start-home-img {
      display: none;
    }
    .apple-button,
    .google-button {
      margin-top: 20px;
      margin-right: 5px;
      width: 160px;
    }
    .app-button a {
      padding: 5px 10px;
    }
    .apple-button .slider-button-icon i {
      font-size: 25px;
    }
    .slider-button-title h3 {
      font-size: 13px;
    }
    .apple-button .slider-button-icon,
    .google-button .slider-button-icon {
      padding: 0px 10px 6px 3px;
    }
    .owl-nav .owl-prev,
    .owl-nav .owl-next {
      background: rgba(255, 255, 255, 0.4) !important;
    }
    .blog-slider .owl-nav .owl-prev {
      left: 0;
      right: auto;
    }
    .blog-slider .owl-nav .owl-next {
      right: 0;
      left: auto;
    }
    .blog-item {
      margin: 20px 0;
    }
    .section-block {
      padding: 60px 0;
    }
    .section-header {
      margin-bottom: 60px;
    }
    .contact-form-block {
      padding: 30px 20px;
      margin: 0;
    }
    .contact-shape img {
      right: 0;
      left: 0;
    }
    #reviews {
      padding: 60px 0;
    }
    #appScreenshots .shape-top,
    #reviews .shape-top,
    #reviews .shape-bottom {
      display: none;
    }
    .review-details-content {
      padding-top: 30px;
    }
    .review-details-content,
    .review-photo-list {
      padding-left: 0;
    }
    .list-clients {
      margin-top: 60px;
    }
    #appScreenshots {
      padding: 60px 0;
    }
    .list_screen_slide .item img {
      height: 450px;
    }
    .list_screen_slide .owl-dots {
      bottom: -60px;
      padding: 0;
    }
    .pricing-btns-group {
      margin-bottom: 50px;
    }
    .pricing-card {
      margin-bottom: 60px;
    }
    footer {
      padding-top: 60px;
    }
    footer .shape-top {
      display: none;
    }
    .footer-title {
      margin-top: 30px;
    }
    .page-header {
      padding: 100px 0;
    }
    .blog-side {
      padding-left: 0;
      margin-top: 30px;
    }
    .blog-details h1 {
      font-size: 26px;
    }
    .share-buttons h6 {
      display: inline-block;
    }
    .post-operations {
      margin-top: 10px;
    }
    .title-box h1 {
      font-size: 30px;
    }
    .section-header h2 {
      font-size: 30px;
    }
    .faq-search {
      width: 100%;
    }
    .btn-read-more {
      margin-bottom: 20px;
    }
    .features-style3 .feature-block.offset-top {
      transform: translateY(0);
    }
    .img-fluid {
      max-width: 100%;
      height: auto;
    }

    .login-side-des {
      display: none;
    }
    .login-form {
      width: 100%;
    }
    .res-logo {
      display: block;
      position: fixed;
      top: 30px;
    }
    .circls-features {
      display: none;
    }
    .custompage-content .logo {
      position: relative;
      top: 0;
    }
    .custompage-content img {
      margin-bottom: 15px;
    }
    .comin-soon-content h1 {
      font-size: 34px;
    }
    .counter-item {
      margin-bottom: 10px;
      font-size: 14px;
      text-align: center;
    }
    .counter-item span {
      display: block;
    }
    .custompage-content .footer {
      position: relative;
      bottom: 0;
      margin-top: 30px;
    }
    .share-buttons a,
    .post-operations a {
      margin-left: 0;
    }
    .download-app-style-1 .download-app-sec {
      padding: 50px 20px;
    }
    .download-app-style-1 .download-app-sec h2 {
      font-size: 24px;
      text-align: center;
    }
    .dw-app-btns {
      text-align: center !important;
    }
    .download-app-style-1 .download-app-sec .dw-app-btns .btn {
      padding: 10px 17px;
      font-size: 14px;
    }
  }
}
