@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.features-style {
  font-size: 16px;
  p {
    color: $primaryGray;
  }

  .feq-icon-box {
    padding: 0 3rem;
    display: table;
    position: relative;
    background: #fff;
    p {
      margin-bottom: 0;
    }
  }
  .faq-icon {
    position: relative;
    width: 70px;
  }

  .faq-icon i:before {
    font-size: 60px;
    background: $primarybackground;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 0;
  }
  .featured-content {
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;
  }
  .featured-content h5 {
    font-weight: 600;
    font-size: 18px;
  }
  .faqs-categories {
    background: linear-gradient(0deg, #f8f9fb 35%, transparent 100%);
  }
  .help-option {
    background: linear-gradient(180deg, #f8f9fb 24%, transparent 49%);
    position: relative;
    padding-top: 160px;
  }
}
