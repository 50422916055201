footer {
  background: $primarybackground;
  background-size: 200% auto;
  position: relative;
  padding-top: 220px;
  .site-logo img {
    filter: brightness(100);
    height: auto;
  }
}
.shape-top {
  background-image: url("/assets/footer-top.svg");
  background-size: 100% 204px;
  top: 0;
  height: 180px;
  z-index: 1;
  margin-top: -1px;
  position: absolute;
  width: 100%;
  pointer-events: none;
  transform: rotate(180deg);
}
footer p {
  color: #fff;
}
footer .site-logo{
  font-weight: 600;
  width: 150px;
  cursor: pointer;
  color: #fff;
}

footer .site-logo img {
  cursor: pointer;
  height: 28px;
}
@keyframes rotateIn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotateIn {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
.footer-title {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 25px;
  position: relative;
  color: #fff;
  margin-bottom: 20px;
}
.footer-title:before {
  position: absolute;
  content: "";
  top: 30%;
  width: 10px;
  height: 10px;
  display: block;
  left: 0;
  animation: rotateIn 5s infinite;
  -webkit-animation: rotateIn 5s infinite;
  -moz-animation: rotateIn 5s infinite;
  box-shadow: 3px 3px 0px 0px rgb(171, 200, 230);
  background-color: #fff;
}
.footer-logo img {
  max-height: 35px;
  margin-bottom: 20px;
}
.footer-social-links a {
  color: #fff;
  background: rgba(255, 255, 255, 0.4);
  width: 35px;
  height: 35px;
  display: inline-block;
  line-height: 38px;
  text-align: center;
  border-radius: 50%;
  margin-right: 4px;
  transition: all 500ms;
}
.footer-social-links a:hover {
  color: $primary;
  background: #fff;
}
.footer-links li a {
  color: #fff;
  margin-bottom: 10px;
  position: relative;
  padding-left: 5px;
}
.footer-links li a:hover {
  color: rgba(255, 255, 255, 0.79);
}
.footer-links li:last-child a {
  margin-bottom: 0;
}

.copyrights p {
  text-align: center;
  padding: 30px 0;
  margin: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.18);
  margin-top: 30px;
}
.copyrights p a {
  color: #fff;
}
.copyrights i:before {
  margin: 0;
}

.newsletter-form {
  background: transparent;
  border-radius: 30px;
  height: 45px;
  width: 100%;
  padding-right: 30%;
  position: relative;
}
.newsletter-form input[type="email"] {
  width: 100%;
  padding-left: 20px;
  height: 45px;
  background: #fff;
  box-shadow: none;
  border: none;
  padding-right: 20px;
  border-radius: 30px 0 0 30px;
}
.newsletter-form input[type="email"]:focus {
  outline: none;
  box-shadow: none;
}
.newsletter-form button {
  position: absolute;
  height: 100%;
  border-radius: 0 30px 30px 0 !important;
  right: -1px;
  top: 0;
  width: 30%;
}

#back-to-top .top {
  position: fixed;
  color: #ffffff;
  background-image: $primarybackground;
  background-size: 200% auto;
  bottom: 45px;
  right: 45px;
  z-index: 999;
  font-size: 16px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
}
#back-to-top .top:hover {
  background-position: right center !important;
}
