@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.how-it-works {
  font-size: $MediumFont;
  p {
    color: $primaryGray;
  }
  .description-block {
    margin-bottom: 10px;
    background: #fff;
    box-shadow: 0px 0px 29px 6px rgba(154, 161, 171, 0.18);
    -webkit-box-shadow: 0px 0px 25px 6px rgba(154, 161, 171, 0.1);
    padding: 10px 20px 20px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 10px;
  }
  .inner-box {
    padding-left: 70px;
    position: relative;
  }
  .inner-box .step_num {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
    text-align: center;
    transition: 0.3s ease;
  }
  .inner-box h3 {
    position: relative;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
  }
  .inner-box p {
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .img-box img {
    animation: floating2 7s infinite;
    -webkit-animation: floating2 7s infinite;
    max-height: 500px;
  }

  @keyframes floating2 {
    0% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      -moz-transform: rotateX(0deg) translateY(0px);
      -ms-transform: rotateX(0deg) translateY(0px);
      -o-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px);
    }
    50% {
      -webkit-transform: rotateX(0deg) translateY(15px);
      -moz-transform: rotateX(0deg) translateY(15px);
      -ms-transform: rotateX(0deg) translateY(15px);
      -o-transform: rotateX(0deg) translateY(15px);
      transform: rotateX(0deg) translateY(15px);
    }
    100% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      -moz-transform: rotateX(0deg) translateY(0px);
      -ms-transform: rotateX(0deg) translateY(0px);
      -o-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px);
    }
  }

  @-webkit-keyframes floating2 {
    0% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      -moz-transform: rotateX(0deg) translateY(0px);
      -ms-transform: rotateX(0deg) translateY(0px);
      -o-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px);
    }
    50% {
      -webkit-transform: rotateX(0deg) translateY(15px);
      -moz-transform: rotateX(0deg) translateY(15px);
      -ms-transform: rotateX(0deg) translateY(15px);
      -o-transform: rotateX(0deg) translateY(15px);
      transform: rotateX(0deg) translateY(15px);
    }
    100% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      -moz-transform: rotateX(0deg) translateY(0px);
      -ms-transform: rotateX(0deg) translateY(0px);
      -o-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px);
    }
  }
}
