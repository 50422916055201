#contact{
    position: relative;
}
#contact .section-header-style2 {
    margin-bottom: 40px;
}
#contact .section-header-style2 h2 {
    font-size: 24px;
    font-weight: 600;
}
#contact .section-header-style2 p{
    font-size: 14px;
}
#contact .form-control{
    font-size: 1.5rem;
}
#contact textarea.form-control{
    height: auto;
    overflow-y: hidden;
}
.bubbles-animate .bubble {
    position: absolute;
    border-radius: 50%;
    z-index: 99;
}

.bubbles-animate .bubble.b_one, .bubbles-animate .bubble.b_four {
    width: 10px;
    height: 10px;
}

.bubbles-animate .bubble.b_two, .bubbles-animate .bubble.b_five {
    width: 30px;
    height: 30px;
}

.bubbles-animate .bubble.b_three, .bubbles-animate .bubble.b_six {
    width: 14px;
    height: 14px;
}

.bubbles-animate .bubble.b_one {
    background: #00c99c;
    top: 40%;
    left: 85px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: spin2 2s infinite alternate;
    animation: spin2 2s infinite alternate;
}

.bubbles-animate .bubble.b_two {
    background: #fca249;
    top: 56%;
    left: 250px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: spin1 2s infinite alternate;
    animation: spin1 2s infinite alternate;
}

.bubbles-animate .bubble.b_three {
    background: #7898a3;
    top: 85%;
    left: 255px;
    -webkit-animation: spin1 1s infinite alternate;
    animation: spin1 1s infinite alternate;
}

.bubbles-animate .bubble.b_four {
    background: #d27dfa;
    top: 42%;
    right: 85px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: spin1 2s infinite alternate;
    animation: spin1 2s infinite alternate;
}

.bubbles-animate .bubble.b_five {
    background: #ff9398;
    top: 56%;
    right: 220px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: spin1 3s infinite alternate;
    animation: spin1 3s infinite alternate;
}

.bubbles-animate .bubble.b_six {
    background: #f1d53b;
    top: 85%;
    right: 55px;
    -webkit-animation: spin2 2s infinite alternate;
    animation: spin2 2s infinite alternate;
}

.bubbles-animate .triangle {
    position: absolute;
}

.bubbles-animate .triangle.b_seven {
    left: 25%;
    top: 400px;
}

.bubbles-animate .triangle.b_eight {
    left: -100px;
    bottom: -45px;
}

.bubbles-animate .triangle.b_nine {
    right: 28%;
    bottom: 250px;
    -webkit-animation: spin2 2s infinite alternate;
    animation: spin2 2s infinite alternate;
}

/*============ keyframes animation  =============*/
@-webkit-keyframes pulse {
    0% {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(2);
        transform: translate(-50%, -50%) scale(2);
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(2);
        transform: translate(-50%, -50%) scale(2);
    }
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: translateX(-10px) scale(0.9);
        transform: translateX(-10px) scale(0.9);
    }
    100% {
        -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
        transform: translateX(30px) scale(1.3) translateY(10px);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: translateX(-10px) scale(0.9);
        transform: translateX(-10px) scale(0.9);
    }
    100% {
        -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
        transform: translateX(30px) scale(1.3) translateY(10px);
    }
}

@-webkit-keyframes spin1 {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@keyframes spin1 {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@-webkit-keyframes spin2 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }
}

@keyframes spin2 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }
}

@-webkit-keyframes spin3 {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    25% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
    50% {
        -webkit-transform: translateX(30px);
        transform: translateX(30px);
    }
    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
    }
}

@keyframes spin3 {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    25% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
    50% {
        -webkit-transform: translateX(30px);
        transform: translateX(30px);
    }
    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
    }
}
@-webkit-keyframes pulsate {
    0% {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(0.5);
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(4);
        transform: translate(-50%, -50%) scale(4);
    }
}

@keyframes pulsate {
    0% {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(0.5);
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(4);
        transform: translate(-50%, -50%) scale(4);
    }
}
.contact-form-block {
    background: #fff;
    position: relative;
    box-shadow: 0px 0px 29px 6px rgba(154, 161, 171, 0.18);
    -webkit-box-shadow: 0px 0px 29px 6px rgba(154, 161, 171, 0.18);

    padding: 60px 50px;
    margin: 0 0px 0 60px;
    border-radius: 30px;
}
.contact-shape img{
    position: absolute;
}
.contact-form{
    margin-top: 30px;
}
.contact-form input{
    margin-bottom: 10px;
}
.contact-form div{
    margin-bottom: 30px;
    

}
.error-message{
    color: red;
    padding-right: 30px;
    padding-left: 30px;
}
.contact-form button {
    margin-top: 20px;
    padding: 12px 25px;
}
.contact-block i{
    font-size: 35px;
}
.contact-block {
    margin-bottom: 25px;
}
.contact-block h4 {
    font-size: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-weight: 600;
}
.contact-block-side {
    position: relative;
    padding-left: 70px;
}
.contact-block-side i{
    position: absolute;
    left: 0;
    
    background: #3264f5;
    
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.contact-block-side i:before{
    font-size: 45px;
    margin: 0;
}
.contact-block-side p{
    margin-bottom: 0
}
.contact-block-side p span {
    display: block;
    margin-bottom: 5px;
}
.contact-block-side p span:last-child{
    margin-bottom: 0;
}


/* ============================================================================= 
#16. Contact Us Page
============================================================================= */
#google-map {
    height: 350px;
    margin-bottom: 30px;
}