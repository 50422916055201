.pricing-btns-group{
    margin-top: 50px;
}
.pricing-btns-group li a {
    position: relative;
    border: 1px solid;
    color: $primary;
    padding: 14px 30px;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    max-width: 140px;
}
.pricing-btns-group li a:hover{
    border: 1px solid;
    color: $primary;
}
.pricing-btns-group li:first-child a{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}
.pricing-btns-group li:last-child a{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}
.pricing-btns-group li a.active, .pricing-btns-group li a.active:hover{
    color: #fff;
    background: $primarybackground;
    background-size: 200% auto;
    border-color: transparent;
}
.pricing-btns-group li a .btn-badge {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    color: #fff;
    background-color: #00c9a7;
    display: inline-block;
    padding: 8px 10px;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    border-radius: 14px;
}
.pricing-card {
    background: #fff;
    box-shadow: 0 0 25px rgba(140, 152, 164, 0.125) !important;
    border-radius: 15px;
}
.card-header{
    color: #fff;
    text-align: center;
    padding: 45px 10px 103px 10px;
    border-radius: 15px 15px 0 0 !important;
}
.card-header h4{
    color: #fff;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 30px;
}
.price-date{
    font-size: 1.5rem;
    padding-left: 5px;
}
.card-header-price{

}
.card-header-price .simbole{
    vertical-align: top !important;
}
.card-header-price .price-num{
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
}
.card-body{
    font-size: $MediumFont;
}
.card-body ul li {
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
}
.card-body ul li span {
    color: $primary;
    background: rgba(30, 103, 193, 0.08);
    border-color: transparent;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 25px;
    text-align: center;
    position: absolute;
    top: -2px;
    left: 0;

}
.card-body .btn  {
    color: $primary;
    background: rgba(30, 103, 193, 0.08);
    padding: 12px 10px;
    margin-top: 30px;
    transition: all 500ms;
}
.card-body .btn:hover{
    background: $primarybackground;
    background-size: 200% auto;
    color: #fff;
}
.pricing-card.top-35{
    margin-top: -35px;
}
header.card-header {
    background: $primarybackground;
    position: relative;
    background-size: 200% auto;
}
