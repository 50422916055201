@import "./abstracts/variables.scss";
@import "./abstracts/mixins.scss";

.headline {
  text-transform: capitalize;
  font-size: $HeadFont;
  line-height: $HeadLineSpacing;
  font-weight: 900;
}

.overview {
  @include grid(3rem, 14vw, 11rem);
  @include mQ(1248px) {
    @include grid(2rem, 20vw, 11rem);
  }
  @include mQ(1022px) {
    @include grid(2rem, 30vw, 11rem);
  }
  @include mQ(450px) {
    @include grid(1rem, 50vw, 11rem);
  }
  .blue-overview {
    background-color: #77d0e849;
  }
  .purple-overview {
    background-color: rgba(146, 122, 244, 0.089);
  }
  article {
    cursor: pointer;
    border-radius: 10px;
    padding: 2rem 1.5rem;
    @include flexBet;
    flex-direction: column;
    h3 {
      text-transform: capitalize;
      i {
        margin-right: 1rem;
      }
    }
  }
}

.charts {
  margin-top: 1rem;
  @include flexBet;
  flex-wrap: wrap;
  canvas {
    width: 30vw !important;
    height: 40vh !important;
    @include mQ(570px) {
      width: 100% !important;
      height: 90vh !important;
      margin: 1rem 0;
    }
  }
}
