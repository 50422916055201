.glossary-sidebar{padding-top: 8px;
padding-bottom: 8px;
text-decoration: none;
font-weight: 400;
font-size: 15px;
line-height: 18px;
padding-left: 5px;
position: fixed;
width: 300px;
}
.glossary-sidebar h6{
    font-size: 1.5rem;
}

.glossary-content{
    width: 60%;
}
.glossary-content h1{
    font-size: 4.1rem;
    color: #04182b;
}
.glossary-content h2{
    color: #04182b;
    font-size: 3.1rem;
    line-height: 4.2rem;
}
.glossary-content-body ul li,.glossary-content-body  ol li{
    margin-left: 35px;
    margin-right: 35px;
  }

.glossary-content h3{
    color: #04182b;
    font-size: 2.2rem;
    line-height: 3.2rem;
}

.glossary-content p,.glossary-content li{
    color: #514b5f;
    font-size: 1.8rem;
    line-height: 3.2rem;
}
.glossary-content ul li{
    list-style:disc;
}
.glossary-content ol li{
    list-style:decimal;
}
.glossary-content li{
    margin-left: 50px;
}
.glossary-pagination{
    font-size: 1.4rem;
    line-height: 2.4rem;
}

.glossary-group-head{
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -.17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b7d8f9;
    border-radius: 1rem;
    width: 5rem;
    height: 5rem;
    font-family: PlacardNext;
    color: #fff;

}

.alphabets-container{
    box-shadow: 0 1px 3px rgba(0,51,99,.15);
    position: sticky;
    width: 80px;
    display: flex;
    justify-content: center;
}

.glossary-group{
    font-size: 16px;
    color: #04182b;
    font-weight: 600;
}

.glossary-group li{
    margin-top: 5px;
}

.glossary-group ul{
    width: 35%;
}

/* Media query for smaller screens */
@media (max-width: 1085px) {
    .glossary-sidebar-container,.alphabets-sidbar{
        display: none;
    }
    .glossary-content,.glossary-group ul{
        width: 100%;
    }
   .glossary-group-content{
    display: contents;
    flex-direction: column;
   }



}